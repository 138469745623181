import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMealDay } from '../interfaces/meal-day.interface';

@Component({
    selector: 'app-meal-days-group',
    templateUrl: './meal-days-group.component.html',
    styleUrls: ['./meal-days-group.component.css']
})
export class MealDaysGroupComponent {
    @Input() mealDays: IMealDay[];
    @Input() addDaysDisabled: boolean;
    @Output() addDayEvent = new EventEmitter();
    @Output() removeDayEvent = new EventEmitter();

    addDay() {
        this.addDayEvent.emit();
    }

    removeDay(index: number) {
        this.removeDayEvent.emit(index);
    }
}
