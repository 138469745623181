import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { IoService } from "../../services/http/io.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MapsAPILoader } from "@agm/core";
import { Location } from "@angular/common";
import {
  base64ToFile,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import { HttpClient } from "@angular/common/http";
import { ValidatorsService } from "./../../services/validators/valdiators.service";
import { Subscription } from 'rxjs';
declare var google: any;
let closeResult = "";
@Component({
  selector: "app-add-restaurant",
  templateUrl: "./add-restaurant.component.html",
  styleUrls: ["./add-restaurant.component.css"],
})
export class AddRestaurantComponent implements OnInit {
  CHANNELS = [
    { key: 'mep', name: 'MyEatPal' },
    { key: 'deliverect', name: 'Deliverect' },
  ];
  DELIVERY_METHODS = [
    { key: 'restaurant', name: 'Restaurant' },
    { key: 'careem', name: 'Careem' },
  ];
  paramObserver: Subscription;
  parentId: number = null;
  parentName: string = null;
  brandId: number = null;
  brandName: string = null;
  logo: boolean = false;
  backgroundlogo: boolean = false;
  image: any;
  logoimage: any;
  showCropper = false;
  enableCropper = false;
  file: any = "";
  finalFile: any;
  logoFinalFile: any;
  imageChangedEvent: any = "";
  imageChangedEvent1: any = "";
  croppedImage: any = "";
  croppedImage1: any = "";
  addRestaurantFrm: FormGroup;
  locationName: any;
  lat: any;
  lng: any;
  scale = 1;
  transform: ImageTransform = {};
  countries: any;
  setTitle: any;
  selectedCountry: any = "";
  countryCode: any = "";
  workingHours: any[] = [
    { monday: null },
    { tuesday: null },
    { wednesday: null },
    { thursday: null },
    { friday: null },
    { saturday: null },
    { sunday: null },
  ];
  @ViewChild("search") search: ElementRef;
  emailPattern = "^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$";
  FORM_KEYS: {
    RESTAURANT_NAME: string;
    CONTACT_NUMBER: string;
    EMAIL: string;
    CITY: string;
    ADDRESS: string;
    LANDMARK: string;
    LATITUDE: string;
    LONGITUDE: string;
    COMMISSION_RATE: string;
    MINIMUM_ORDER_AMOUNT: string;
    COUNTRY: string;
    WORKING_HOURS: string;
    SMS_NOTIFICATION: string;
    SMS_PHONE: string;
    CHANNEL: string;
    CHANNEL_LINK_ID: string;
    DELIVERY_METHOD: string;
    BRANCH_NAME: string;
    NOT_ON_MYEATPAL: string;
    EXTERNAL_LINK: string;
    RESTAURANT_DETAILS: string;
  };
  constructor(
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public location: Location,
    private modalService: NgbModal,
    private io: IoService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private ValidatorsService: ValidatorsService
  ) {
    this.FORM_KEYS = {
      RESTAURANT_NAME: "restaurantName",
      CONTACT_NUMBER: "contactNumber",
      EMAIL: "email",
      CITY: "city",
      ADDRESS: "address",
      LANDMARK: "landmark",
      LATITUDE: "latitude",
      LONGITUDE: "longitude",
      COMMISSION_RATE: "commissionRate",
      MINIMUM_ORDER_AMOUNT: "minimumOrderAmount",
      COUNTRY: "country",
      WORKING_HOURS: "workingHours",
      SMS_NOTIFICATION: "smsNotification",
      RESTAURANT_DETAILS: "restaurantDetails",
      SMS_PHONE: "smsPhone",
      CHANNEL: "channel", 
      CHANNEL_LINK_ID: "channelLinkId",
      DELIVERY_METHOD: "deliveryMethod", 
      BRANCH_NAME: "branchName",
      NOT_ON_MYEATPAL: 'notOnMyeatpal',
      EXTERNAL_LINK: 'externalLink',
    };
    this.addRestaurantFrm = this.fb.group({
      [this.FORM_KEYS.RESTAURANT_NAME]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.BRANCH_NAME]: [null],
      [this.FORM_KEYS.CONTACT_NUMBER]: [
        null,
        Validators.compose([
          Validators.required,
          this.ValidatorsService.validatePhoneNumber(
            () => this.countryCode,
            "phone"
          ),
        ]),
      ],
      [this.FORM_KEYS.EMAIL]: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      [this.FORM_KEYS.CITY]: [null, Validators.compose([Validators.required])],
      [this.FORM_KEYS.ADDRESS]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.LANDMARK]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.RESTAURANT_DETAILS]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.LATITUDE]: [""],
      [this.FORM_KEYS.LONGITUDE]: [""],
      [this.FORM_KEYS.COMMISSION_RATE]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.MINIMUM_ORDER_AMOUNT]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.COUNTRY]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.WORKING_HOURS]: [
        null,
        [ValidatorsService.validateWorkingHours],
      ],
      [this.FORM_KEYS.SMS_NOTIFICATION]: [false],
      [this.FORM_KEYS.NOT_ON_MYEATPAL]: [false],
      [this.FORM_KEYS.EXTERNAL_LINK]: [null],
      [this.FORM_KEYS.SMS_PHONE]: [null],
      [this.FORM_KEYS.CHANNEL]: [
        this.CHANNELS[0].key,
        Validators.compose([Validators.required])
      ],
      [this.FORM_KEYS.CHANNEL_LINK_ID]: [null],
      [this.FORM_KEYS.DELIVERY_METHOD]: [
        this.DELIVERY_METHODS[0].key,
        Validators.compose([Validators.required])
      ],
    });
    this.setCustomValidators();
  }

  setCustomValidators(): void {
    const smsNotificationControl = this.addRestaurantFrm.get(
      this.FORM_KEYS.SMS_NOTIFICATION
    );
    const smsPhoneControl = this.addRestaurantFrm.get(this.FORM_KEYS.SMS_PHONE);
    smsNotificationControl.valueChanges.subscribe((value) => {
      if (value) {
        smsPhoneControl.setValidators([
          Validators.required,
          this.ValidatorsService.validatePhoneNumber(
            () => this.countryCode,
            "mobile"
          ),
        ]);
      } else {
        smsPhoneControl.setValidators(null);
      }
      smsPhoneControl.updateValueAndValidity();
    });

    this.addRestaurantFrm.get(this.FORM_KEYS.CHANNEL).valueChanges.subscribe((value: string) => {
      if (value !== this.CHANNELS[0].key) {
        this.addRestaurantFrm.get(this.FORM_KEYS.CHANNEL_LINK_ID).setValidators(Validators.required);
      } else {
        this.addRestaurantFrm.get(this.FORM_KEYS.CHANNEL_LINK_ID).clearValidators();
      }

      this.addRestaurantFrm.get(this.FORM_KEYS.CHANNEL_LINK_ID).updateValueAndValidity();
    });

    this.addRestaurantFrm.get(this.FORM_KEYS.NOT_ON_MYEATPAL).valueChanges.subscribe((value: boolean) => {
      const contactNo = this.addRestaurantFrm.get(this.FORM_KEYS.CONTACT_NUMBER);
      const smsPhoneControl = this.addRestaurantFrm.get(this.FORM_KEYS.SMS_PHONE);
      const email = this.addRestaurantFrm.get(this.FORM_KEYS.EMAIL);
      const address = this.addRestaurantFrm.get(this.FORM_KEYS.ADDRESS);
      const city = this.addRestaurantFrm.get(this.FORM_KEYS.CITY);
      const landmark = this.addRestaurantFrm.get(this.FORM_KEYS.LANDMARK);
      const commission = this.addRestaurantFrm.get(this.FORM_KEYS.COMMISSION_RATE);
      const minimumOrderAmount = this.addRestaurantFrm.get(this.FORM_KEYS.MINIMUM_ORDER_AMOUNT);
      const deliveryMethod = this.addRestaurantFrm.get(this.FORM_KEYS.DELIVERY_METHOD);
      const channelLinkId = this.addRestaurantFrm.get(this.FORM_KEYS.CHANNEL_LINK_ID);
      const workingHours = this.addRestaurantFrm.get(this.FORM_KEYS.WORKING_HOURS);
      const restaurantDetails = this.addRestaurantFrm.get(this.FORM_KEYS.RESTAURANT_DETAILS);

      if (value) {
        contactNo.clearValidators();
        smsPhoneControl.clearValidators();
        email.clearValidators();
        address.clearValidators();
        city.clearValidators();
        landmark.clearValidators();
        commission.clearValidators();
        minimumOrderAmount.clearValidators();
        deliveryMethod.clearValidators();
        channelLinkId.clearValidators();
        workingHours.clearValidators();
        restaurantDetails.clearValidators();
      } else {
        contactNo.setValidators(
          Validators.compose([
            Validators.required,
            this.ValidatorsService.validatePhoneNumber(
              () => this.countryCode,
              "phone"
            ),
          ])
        );
        smsPhoneControl.setValidators(
          Validators.compose([
            Validators.required,
            this.ValidatorsService.validatePhoneNumber(
              () => this.countryCode,
              "mobile"
            ),
          ])
        );
        email.setValidators(
          Validators.compose([
            Validators.required,
            Validators.pattern(this.emailPattern),
          ])
        );
        address.setValidators(
          Validators.compose([Validators.required])
        );
        city.setValidators(Validators.compose([Validators.required]));
        landmark.setValidators(Validators.compose([Validators.required]));
        commission.setValidators(Validators.compose([Validators.required]));
        minimumOrderAmount.setValidators(Validators.compose([Validators.required]));
        deliveryMethod.setValidators(Validators.compose([Validators.required]));
        if (this.addRestaurantFrm.get(this.FORM_KEYS.CHANNEL).value !== this.CHANNELS[0].key) {
          channelLinkId.setValidators(Validators.required);
        } else {
          channelLinkId.clearValidators();
        }
        workingHours.setValidators(
          Validators.compose([
            this.ValidatorsService.validateWorkingHours
          ])
        );
        restaurantDetails.setValidators(Validators.compose([Validators.required]));
      }

      contactNo.updateValueAndValidity();
      smsPhoneControl.updateValueAndValidity();
      email.updateValueAndValidity();
      address.updateValueAndValidity();
      city.updateValueAndValidity();
      landmark.updateValueAndValidity();
      commission.updateValueAndValidity();
      minimumOrderAmount.updateValueAndValidity();
      deliveryMethod.updateValueAndValidity();
      channelLinkId.updateValueAndValidity();
      workingHours.updateValueAndValidity();
      restaurantDetails.updateValueAndValidity();
    });
  }

  ngOnInit(): void {
    this.getLoc();
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.search?.nativeElement,
        { types: [] }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // get the place result //
          const place: typeof google.maps.places.PlaceResult =
            autocomplete.getPlace();
          if (!place.place_id) {
            window.alert("Please select an option from the dropdown list.");
            return;
          }
          this.locationName = place.name;
          // verify result //
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // set latitude, longitude and zoom //
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.addRestaurantFrm.patchValue({
            [this.FORM_KEYS.ADDRESS]: this.locationName,
            [this.FORM_KEYS.LATITUDE]: this.lat,
            [this.FORM_KEYS.LONGITUDE]: this.lng,
          });
          //this.zoom = 12;
        });
      });
    });

    this.paramObserver = this.route.queryParams.subscribe(({ parentId, parentName, brandId, brandName, notOnMyeatpal, externalLink }) => {
      if (parentId && parentName) {
        this.parentId = Number(parentId);
        this.parentName = parentName;

        this.addRestaurantFrm.patchValue({
          [this.FORM_KEYS.RESTAURANT_NAME]: this.parentName,
        });
      }

      if (brandId && brandName) {
        this.brandId = Number(brandId);
        this.brandName = brandName;

        this.addRestaurantFrm.patchValue({
          [this.FORM_KEYS.RESTAURANT_NAME]: this.brandName,
        });
      }

      if (notOnMyeatpal === 'true') {
        this.addRestaurantFrm.patchValue({
          [this.FORM_KEYS.NOT_ON_MYEATPAL]: true,
        });

        this.addRestaurantFrm.get(this.FORM_KEYS.NOT_ON_MYEATPAL).disable();
      }

      if (externalLink) {
        this.addRestaurantFrm.patchValue({
          [this.FORM_KEYS.EXTERNAL_LINK]: externalLink,
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  get f() {
    return this.addRestaurantFrm.controls;
  }

  getLoc() {
    /* this.http.get<any>("./assets/data/country.json").subscribe((data) => {
      this.countries = data.countries;
      if (this.countries.length == 1) {
        this.selectedCountry = this.countries[0].name;
        this.countryCode = this.countries[0].code;
        this.addRestaurantFrm.patchValue({ countryType: this.selectedCountry });
      }
    }); */
    this.countries = [
      {
        code: "+971",
        name: "United Arab Emirates",
      },
    ];
    this.selectedCountry = this.countries[0].name;
    this.countryCode = this.countries[0].code;
    this.addRestaurantFrm.patchValue({
      [this.FORM_KEYS.COUNTRY]: this.selectedCountry,
    });
  }

  changeCountry(event) {
    this.selectedCountry = event.target.value;
    let index = this.countries.filter(
      (item) => item.name === this.selectedCountry
    );
    this.countryCode = index[0].code;
  }
  updateFormWorkingHours(newHours) {
    this.addRestaurantFrm.patchValue({
      [this.FORM_KEYS.WORKING_HOURS]: newHours,
    });
  }

  handleHoursChange(value, index, field): void {
    let current = { ...this.workingHours[index] };
    current[Object.keys(current)[0]] = {
      ...current[Object.keys(current)[0]],
      [field]: value,
    };
    this.workingHours[index] = current;
    this.updateFormWorkingHours(this.workingHours);
  }

  getDayFieldValue(item, field): string {
    if (field === "day") {
      return Object.keys(item.value)[0];
    } else {
      return null;
    }
  }

  isDayOpen(item) {
    return item.value[Object.keys(item.value)[0]] !== null;
  }

  handleDayOpenClose(index) {
    let current = { ...this.workingHours[index] };
    if (current[Object.keys(current)[0]] == null) {
      current[Object.keys(current)[0]] = { from: "09:00", to: "22:00" };
    } else {
      current[Object.keys(current)[0]] = null;
    }
    this.workingHours[index] = current;
    this.updateFormWorkingHours(this.workingHours);
  }

  async addRestaurant(value) {
    if (!this.addRestaurantFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.addRestaurantFrm.markAllAsTouched();
      return;
    }

    const notOnMyeatpal = this.addRestaurantFrm.get(this.FORM_KEYS.NOT_ON_MYEATPAL).value;

    if (!this.image && !notOnMyeatpal) {
      swal.fire({
        title: "Oops...",
        text: "Please add background image.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    if (!this.logoimage) {
      swal.fire({
        title: "Oops...",
        text: "Please add logo image.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    if (!notOnMyeatpal) {
      await this.saveImage();
    }
    await this.logoImage();
    let hoursParsed = {};
    this.workingHours.forEach((item) => {
      hoursParsed[Object.keys(item)[0]] =
        item[Object.keys(item)[0]] == null ? [] : [item[Object.keys(item)[0]]];
    });

    let payload = {
      restaurantName: value[this.FORM_KEYS.RESTAURANT_NAME],
      restaurantDetails: notOnMyeatpal ? null : value[this.FORM_KEYS.RESTAURANT_DETAILS],
      branchName: notOnMyeatpal ? null : value[this.FORM_KEYS.BRANCH_NAME],
      contactNo: notOnMyeatpal ? null : value[this.FORM_KEYS.CONTACT_NUMBER].toString(),
      email: notOnMyeatpal ? null : value[this.FORM_KEYS.EMAIL],
      city: notOnMyeatpal ? null : value[this.FORM_KEYS.CITY],
      country: value[this.FORM_KEYS.COUNTRY],
      countryCode: this.countryCode,
      address: notOnMyeatpal ? null : value[this.FORM_KEYS.ADDRESS],
      landmark: notOnMyeatpal ? null : value[this.FORM_KEYS.LANDMARK],
      latitude: value[this.FORM_KEYS.LATITUDE],
      longitude: value[this.FORM_KEYS.LONGITUDE],
      imageUrl: notOnMyeatpal ? null : this.image,
      restaurantLogo: this.logoimage,
      workingHours: notOnMyeatpal ? null : hoursParsed,
      commissionRate: notOnMyeatpal ? null : value[this.FORM_KEYS.COMMISSION_RATE],
      minimumOrderAmount: notOnMyeatpal ? 0 : value[this.FORM_KEYS.MINIMUM_ORDER_AMOUNT],
      channel: notOnMyeatpal ? this.CHANNELS[0].key : value[this.FORM_KEYS.CHANNEL],
      channelLinkId: notOnMyeatpal ? null : value[this.FORM_KEYS.CHANNEL_LINK_ID],
      deliveryMethod: notOnMyeatpal ? this.DELIVERY_METHODS[0].key : value[this.FORM_KEYS.DELIVERY_METHOD],
      notifyOnNewOrder: notOnMyeatpal ? false : value[this.FORM_KEYS.SMS_NOTIFICATION],
      parentId: this.parentId,
      parentBrandId: this.brandId,
      notOnMyeatpal: notOnMyeatpal,
      externalLink: notOnMyeatpal && value[this.FORM_KEYS.EXTERNAL_LINK]?.trim() ? value[this.FORM_KEYS.EXTERNAL_LINK].trim() : null,
      notificationNumber: notOnMyeatpal ? null :
        value[this.FORM_KEYS.SMS_NOTIFICATION]
          ? value[this.FORM_KEYS.SMS_PHONE]
          : null,
    };
    this.io
      .apicall(payload, "restaurant/add-restaurant", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.router.navigate(["/resturant/restaurant-list"]);
          this.addRestaurantFrm.reset();
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  open(content, status) {
    if (status == "background") {
      this.setTitle = "Restaurant Background image";
      this.logo = false;
      this.backgroundlogo = true;
    }
    if (status == "logo") {
      this.setTitle = "Restaurant Logo image";
      this.backgroundlogo = false;
      this.logo = true;
    }
    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.croppedImage1 = "";
    this.imageChangedEvent1 = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
      this.image = this.imageChangedEvent;
    }
    //this.fileToUpload = event.target.files[0];
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }
  imageLoaded(image: HTMLImageElement) {
    this.showCropper = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  fileChangeEvent1(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.logoimage = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent1 = event;
      this.logoimage = this.imageChangedEvent1;
    }
    //this.fileToUpload = event.target.files[0];
  }
  imageCropped1(event: ImageCroppedEvent) {
    this.croppedImage1 = event.base64;
    this.logoFinalFile = base64ToFile(this.croppedImage1);
    this.enableCropper = true;
  }
  imageLoaded1(image: HTMLImageElement) {
    this.showCropper = true;
  }
  cropperReady1() {
    // cropper ready
  }
  loadImageFailed1() {
    // show message
  }

  async saveImage(): Promise<any> {
    return new Promise((resolve, reject) => {
      let fd = new FormData();
      fd.append("file", this.finalFile);
      this.io
        .apicall(fd, "uploads/restaurant-img-upload", "POST")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            this.image = res["result"][0].fileUrl;
            resolve(res["result"][0].fileUrl);
            this.modalService.dismissAll();
          }
        })
        .catch((err) => {
          if (err) {
            reject(err);
          }
        });
    });
  }

  async logoImage(): Promise<any> {
    return new Promise((resolve, reject) => {
      const fd = new FormData();
      fd.append("file", this.logoFinalFile);
      this.io
        .apicall(fd, "uploads/restaurant-img-upload", "POST")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            this.logoimage = res["result"][0].fileUrl;
            resolve(res["result"][0].fileUrl);
            this.modalService.dismissAll();
          }
        })
        .catch((err) => {
          if (err) {
            reject(err);
          }
        });
    });
  }

  closeModal() {
    this.modalService.dismissAll();
    this.image = "";
    this.logoimage = "";
    this.enableCropper = false;
  }
  save() {
    this.modalService.dismissAll();
  }
}
