import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { IoService } from '../../services/http/io.service';
import swal from "sweetalert2";
import { MealPlanCreatorState } from '../interfaces/creator-state.interface';
import { TagManagementService } from "./../../services/tag-management/tag-management.service";
import Swal from "sweetalert2";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-meal-plan-creator',
    templateUrl: './meal-plan-creator.component.html',
    styleUrls: ['./meal-plan-creator.component.css']
})
export class MealPlanCreatorComponent implements OnInit {
    imageChangedEvent: any = '';
    croppedImage: any = '';
    closeResult: any = '';
    enableCropper: any = false;
    finalFile: any = '';
    file: any;
    transform: ImageTransform = {};
    changedImage: string = '';
    orderChanged: boolean = false;

    images: string[] = [];
    planName: string = '';
    planDuration: number = 1;
    expiryDate: any = '';
    planTarget: string = '';
    planPrice: string = '';
    planDescription: string = '';
    isActive: boolean = false;
    isPublished: boolean = false;

    restaurants: { id: number; name: string; children: { id: number; name: string; }[] }[];
    recipeCreators: { id: number; name: string; }[];

    dietaryPreferences: {
        id: number;
        name: string;
        preferences: {
            id: number;
            name: string;
        }[];
    }[];
    tags: {
        id: number;
        name: string;
        tags: {
            id: number;
            name: string;
        }[];
    }[];

    step: number = 1;
    STEPS_COUNT: number = 5;

    creatorState: MealPlanCreatorState = {
        prefillBreakfast: true,
        prefillLunch: true,
        prefillSnack: true,
        prefillDinner: true,
        minCost: 0,
        maxCost: 1000000,
        minCals: 0,
        maxCals: 1000000,
        minProtein: 0,
        maxProtein: 1000000,
        minCarbs: 0,
        maxCarbs: 1000000,
        minFat: 0,
        maxFat: 1000000,
        includeRecipes: true,
        includeDishesOnMEP: true,
        includeDishesNotOnMEP: true,
        dietaryPreferences: {},
        tags: {},
        filterCalsMin: 0,
        filterCalsMax: 1000000,
        filterRestaurants: [],
        selectedParentRestaurants: [],
        filterRecipeCreators: [],
        dishOptions: [],
        recipeOptions: [],
        currentDayIndex: 0,
        days: [],
    };

    constructor(
        private modalService: NgbModal,
        private io: IoService,
        private toastr: ToastrService,
        private router: Router,
        private tagService: TagManagementService,
    ) { }

    ngOnInit(): void {
        this.getTags();
        this.getRestaurantList();
        this.getRecipeCreatorList();
    }

    getTags(): void {
        this.tagService.listTagsAndDietPreferences().subscribe(
            (res) => {
                if (res["serverResponse"].code === 200) {
                    this.dietaryPreferences = res.result.filter((_, index) => index < 3);
                    this.tags = res.result.filter((_, index) => index >= 3);

                    this.dietaryPreferences.forEach((pref) => {
                        this.creatorState.dietaryPreferences[pref.id] = { id: pref.id, selections: [] };
                    });

                    this.tags.forEach((tag) => {
                        this.creatorState.tags[tag.id] = { id: tag.id, selections: [] };
                    });
                }
            },
            () => {
                Swal.fire({
                    title: "Oops...",
                    text: "Something went wrong while getting tags!",
                    icon: "warning",
                    confirmButtonColor: "#442DFF;",
                    confirmButtonText: "ok",
                });
            }
        );
    }

    async getRestaurantList() {
        const resturantList = (await this.io.apicall({}, 'restaurant/list-for-mealplan-creator', 'GET') as any).restaurants;

        const getRestaurantName = (restaurantName: string, branchName: string | null) => `${restaurantName}${branchName?.trim() ? ` - ${branchName}` : ''}`;

        this.restaurants = resturantList.filter((res) => !res.parentId).map((parentRestaurant) => {
            const children = resturantList.filter((res) => res.parentId === parentRestaurant.id).map((res) => ({
                id: res.id,
                name: getRestaurantName(res.name, res.branchName),
            }));

            children.unshift({ id: parentRestaurant.id, name: getRestaurantName(parentRestaurant.name, parentRestaurant.branchName) });

            return {
                id: parentRestaurant.id,
                name: parentRestaurant.name,
                children,
            };
        });
    }

    async getRecipeCreatorList() {
        const recipeCreatorList = (await this.io.apicall({}, 'creator/list-for-mealplan-creator', 'GET') as any).recipeCreators;

        this.recipeCreators = recipeCreatorList.map((cr) => ({
            id: cr.id,
            name: cr.name,
        }));
    }

    parseExpiryDate(expiryDate: string) {
        if (!expiryDate) return '';

        const dateParts = expiryDate.split('-');

        return {
            year: +dateParts[0],
            month: +dateParts[1],
            day: +dateParts[2].split('T')[0],
        };
    }

    openModal(content) {
        this.imageChangedEvent = '';
        this.croppedImage = '';

        this.modalService
            .open(content, { ariaLabelledBy: 'modal-basic-title' })
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    return reason;
                }
            );
    }

    closeModal() {
        this.modalService.dismissAll();
        this.enableCropper = false;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.finalFile = base64ToFile(this.croppedImage);
        this.enableCropper = true;
    }

    cropperReady(sourceImageDimensions: Dimensions) {
    }

    imageLoaded(image: HTMLImageElement) {
    }

    fileChangeEvent(event: any) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.changedImage = reader.result as string;
            };
            this.file = event.target.files[0];
            reader.readAsDataURL(event.target.files[0]);
            this.imageChangedEvent = event;
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        this.orderChanged = true;

        moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    }

    removeImage(index: number) {
        this.images.splice(index, 1);
    }

    async saveImage() {
        const fd = new FormData();

        fd.append('file', this.finalFile);

        try {
            const res = await this.io.apicall(fd, 'uploads/meal-plan-img', 'POST');

            if (res['serverResponse'].code === 200) {
                this.images.push(res['result'][0].fileUrl);

                this.toastr.success('Image uploaded!');
                this.modalService.dismissAll();

                return res['result'][0].fileUrl;
            }
        } catch (err) {
            swal.fire({
                title: 'Oops...',
                text:
                    err?.error?.serverResponse?.message ||
                    'Something went wrong while uploading image',
                icon: 'warning',
                confirmButtonColor: '#442DFF;',
                confirmButtonText: 'ok',
            });
        }

        return null;
    }

    showValidationError(message: string) {
        swal.fire({
            title: 'Form invalid!',
            text: message,
            icon: 'warning',
            confirmButtonColor: '#442DFF;',
            confirmButtonText: 'ok',
        });
    }

    savePlanBtnDisabled() {
        if (!this.planName.trim().length) {
            this.showValidationError('Please fill plan name');

            return true;
        };

        if (+this.planDuration < 1) {
            this.showValidationError('Plan duration cannot be less than 1 day');

            return true;
        }

        if (!this.planTarget.trim().length) {
            this.showValidationError('Please fill plan target');

            return true;
        }

        if (!this.planPrice.trim().length) {
            this.showValidationError('Please fill plan price');

            return true;
        }

        if (this.images.length < 3) {
            this.showValidationError('Please upload a minimum of 3 images for your meal plan.');

            return true;
        }

        if (this.images.length > 10) {
            this.showValidationError('You can upload a maximum of 10 images for your meal plan, please remove some of the extra images and try again.');

            return true;
        }

        if (!this.creatorState.days.length) {
            this.showValidationError('Please add days to the meal plan');

            return true;
        }

        for (let i = 0; i < this.creatorState.days.length; i++) {
            const day = this.creatorState.days[i];

            if (!day.meals.length) {
                this.showValidationError(`Please add meals to day ${i + 1}`);

                return true;
            }

            for (let j = 0; j < day.meals.length; j++) {
                const meal = day.meals[j];

                if (!meal.name?.trim()) {
                    this.showValidationError(`Please fill meal #${j + 1} name in day ${i + 1}`);

                    return true;
                }

                if (!meal.items.length) {
                    this.showValidationError(`Please add options to day ${i + 1}, meal ${j + 1}`);

                    return true;
                }
            }
        }

        return false;
    }

    async savePlan() {
        if (this.savePlanBtnDisabled()) return;

        let parsedExpiryDate = null;

        if (this.expiryDate) {
            parsedExpiryDate = `${this.expiryDate.year}-${this.expiryDate.month}-${this.expiryDate.day}`;
        }

        let description = null;

        if (this.planDescription.trim()) {
            description = this.planDescription.trim();
        }

        const icons: { img: string; restaurant: boolean; }[] = [];

        const days = this.creatorState.days.map((day, index) => ({
            name: `Day ${index + 1}`,
            meals: day.meals.map((meal) => ({
                name: meal.name,
                items: meal.items.map((item) => {
                    if (item?.ownerIcon?.trim()) icons.push({
                        img: item.ownerIcon,
                        restaurant: !!item.restaurantName,
                    });

                    return {
                        id: item.id,
                        type: item.restaurantName ? 'order' : 'cook',
                    };
                }),
            })),
        }));

        const sortedIcons = icons.filter((ic) => ic.restaurant).concat(icons.filter((ic) => !ic.restaurant));

        const uniqueIconsSet = new Set<string>();
        const uniqueIcons: string[] = [];

        sortedIcons.forEach((ic) => {
            if (!ic?.img?.trim()) return;

            if (uniqueIconsSet.has(ic.img)) return;

            uniqueIconsSet.add(ic.img);
            uniqueIcons.push(ic.img);
        });

        const payloadIcons = uniqueIcons.slice(0, 15);

        const payload = {
            name: this.planName.trim(),
            duration: +this.planDuration,
            expiryDate: parsedExpiryDate,
            target: this.planTarget.trim(),
            price: this.planPrice.trim(),
            description,
            days,
            coverImages: this.images,
            icons: payloadIcons,
        };

        try {
            await this.io.apicall(payload, 'meal-plans/portal/create', 'POST');

            this.toastr.success('Plan saved!');

            this.router.navigate(['/meal-plan/list']);
        } catch {
            swal.fire({
                title: 'Oops...',
                text: 'Failed to save plan, please try again later!',
                icon: 'warning',
                confirmButtonColor: '#442DFF;',
                confirmButtonText: 'ok',
            });
        }
    }

    previousNavButtonDisabled() {
        return this.step <= 1;
    }

    nextNavButtonDisabled() {
        return this.step >= this.STEPS_COUNT;
    }

    previousNavHandler() {
        this.step--;
    }

    nextNavHandler() {
        this.step++;
    }
}
