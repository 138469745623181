import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISingleMeal } from '../interfaces/single-meal.interface';

@Component({
    selector: 'app-meals-group',
    templateUrl: './meals-group.component.html',
    styleUrls: ['./meals-group.component.css']
})
export class MealsGroupComponent {
    @Input() meals: ISingleMeal[];
    @Input() addMealDisabled: boolean;
    @Output() addMealEvent = new EventEmitter();
    @Output() removeMealEvent = new EventEmitter();

    addMeal() {
        this.addMealEvent.emit();
    }

    removeMeal(index: number) {
        this.removeMealEvent.emit(index);
    }
}
