import { Component, Input, OnInit } from "@angular/core";
import { MenuProduct } from '../../../interfaces/menu-product.interface';
import { MenuFetchProductService } from '../../../services/menu-fetch-product.service';
import { SubProduct } from '../../../interfaces/sub-product.interface';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IoService } from '../../../../services/http/io.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-menu-product",
  templateUrl: "./menu-product.component.html",
  styleUrls: ["./menu-product.component.css"],
})
export class MenuProductComponent implements OnInit {
  @Input() product: MenuProduct

  subProducts: SubProduct[] = []
  showSubProducts = false

  image: string = '';

  imageChangedEvent: any = '';
  croppedImage: any = '';
  closeResult: any = '';
  enableCropper: any = false;
  finalFile: any = '';
  file: any;
  transform: ImageTransform = {};

  constructor(
    private menuFetchProductService: MenuFetchProductService,
    private modalService: NgbModal,
    private io: IoService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  linkAreaClickHandler(event) {
    event.stopPropagation();
  }

  toggleSubProductList() {
    this.showSubProducts = !this.showSubProducts

    if (this.showSubProducts) {
      this.getSubProducts()
    }
  }

  async getSubProducts() {
    const res = await this.menuFetchProductService.getProduct(this.product.mepProductId)

    this.subProducts = res.subProducts;
  }

  openModal(content, event) {
    event.stopPropagation();

    this.imageChangedEvent = '';
    this.croppedImage = '';

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  closeModal() {
    this.modalService.dismissAll();
    this.enableCropper = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
  }

  imageLoaded(image: HTMLImageElement) {
  }

  fileChangeEvent(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
    }
  }

  async saveImage() {
    const fd = new FormData();

    fd.append('file', this.finalFile);

    try {
      const res = await this.io.apicall(fd, 'uploads/meal-plan-img', 'POST');

      if (res['serverResponse'].code !== 200) return;

      this.image = res['result'][0].fileUrl;

      await this.io.apicall({ img: this.image }, `dish/portal/update-dish-img/${this.product.dishId}`);

      this.product.dishImg = this.image;

      this.toastr.success('The dish image has been successfully updated.');
      this.modalService.dismissAll();

      return res['result'][0].fileUrl;
    } catch (err) {
      swal.fire({
        title: 'Oops...',
        text:
          'There was an error uploading the image, please refresh and try again.',
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    }

    return null;
  }
}
