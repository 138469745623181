import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FoodScienceProduct } from '../../../interfaces/food-science-product.interface';
import { IoService } from "../../../../services/http/io.service";
import { DishService } from "../../../../services/dish/dish.service";
import Swal from "sweetalert2";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: "app-menu-food-science-item-row",
  templateUrl: "./menu-food-science-item-row.component.html",
  styleUrls: ["./menu-food-science-item-row.component.css"],
})
export class MenuFoodScienceItemRowComponent implements OnInit {
  @Input() product: FoodScienceProduct;
  @Input() tags;
  @Input() avoidancesId;

  @Output() reloadProduct: EventEmitter<any> = new EventEmitter();

  editMode = false;
  expandAvoidances = false;
  tagsData;

  currentDishTags: any[] = [];

  editDishForm: FormGroup;

  FORM_KEYS: {
    DISH_NAME: string;
    CARBS: string;
    PROTEIN: string;
    FAT: string;
    CALORIES: string;
  };

  dropdownSettings: IDropdownSettings;
  tagsDropdownSettings: IDropdownSettings;

  constructor(
    private io: IoService,
    private fb: FormBuilder,
    private loader: NgxUiLoaderService,
    private dishService: DishService,
  ) {
    this.FORM_KEYS = {
      DISH_NAME: "dishName",
      CARBS: "carbs",
      PROTEIN: "protein",
      FAT: "fat",
      CALORIES: "calories",
    };
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
    this.tagsDropdownSettings = {
      singleSelection: false,
      idField: "tagId",
      textField: "tagName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
  }

  ngOnInit(): void {
    this.buildForm();
    this.addTagsToForm();
    this.setEditMode(false);
  }

  buildForm(): void {
    this.editDishForm = this.fb.group({
      [this.FORM_KEYS.DISH_NAME]: ["", Validators.required],
      [this.FORM_KEYS.CALORIES]: ["", Validators.required],
      [this.FORM_KEYS.CARBS]: [""],
      [this.FORM_KEYS.PROTEIN]: [""],
      [this.FORM_KEYS.FAT]: [""],
    });
  }

  setEditMode(editMode) {
    this.editMode = editMode;

    if (editMode) {
      const selectedDish: any = this.product.dish;

      this.currentDishTags = selectedDish.nutritionalInfo.map(v => v);

      this.tags?.forEach((tag) => {
        if (tag?.name !== "Avoidances") {
          if (tag?.preferences) {
            const selectedItems =
              selectedDish?.dietaryPreferences?.[tag?.id] || [];
            const selectedTags = [];
            for (let i = 0; i < selectedItems?.length; i++) {
              selectedTags.push(
                tag?.preferences.find((item) => item?.id == selectedItems[i])
              );
            }
            this.editDishForm.get(tag?.name).setValue(selectedTags);
          } else {
            const selectedItems = selectedDish?.headTagInfo?.find(
              (item) => item.headId == tag?.id
            )?.tags;
            this.editDishForm.get(tag?.name).setValue(
              selectedItems?.map((item) => {
                return { id: item.tagId, name: item.tagName };
              }) || []
            );
          }
        } else {
          const allAvoidances = this.getAvoidancesList();
          const selectedAvoidances = [];
          allAvoidances.map((avoid) => {
            selectedAvoidances.push(this.isAvoidanceSelected(avoid?.id));
          });
          this.editDishForm.get(tag?.name).setValue(selectedAvoidances);
        }
      });

      this.editDishForm.patchValue({
        [this.FORM_KEYS.DISH_NAME]: selectedDish.dishName,
        [this.FORM_KEYS.CARBS]: selectedDish.carbs,
        [this.FORM_KEYS.PROTEIN]: selectedDish.protein,
        [this.FORM_KEYS.FAT]: selectedDish.fat,
        [this.FORM_KEYS.CALORIES]: selectedDish.totalCalories,
      });
    } else {
      this.currentDishTags = [];
      this.editDishForm?.reset();
    }
  }

  async publishDish() {
    try {
      const res = await this.io.apicall({ dishId: this.product.dish.id }, 'dish/dish-publish-unpublish', 'POST');

      Swal.fire({
        title: 'Success',
        text: res['serverResponse'].message,
        icon: 'success',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });

      this.product.dish.isPublished = true;
    }
    catch (err) {
      Swal.fire({
        title: 'Oops...',
        text: this.io.data_.serverResponse.message,
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    }
  }

  async setDishActiveStatus(isActive: boolean) {
    try {
      const res = await this.io.apicall({ dishId: this.product.dish.id, isActive }, 'dish/change-dish-status', 'POST');

      Swal.fire({
        title: 'Success',
        text: res['serverResponse'].message,
        icon: 'success',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });

      this.product.dish.isActive = isActive;
    }
    catch (err) {
      Swal.fire({
        title: 'Oops...',
        text: this.io.data_.serverResponse.message,
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    }
  }

  addTagsToForm(): void {
    this.tags?.forEach((tag) => {
      if (tag?.name != "Avoidances") {
        this.editDishForm.addControl(tag?.name, new FormControl([]));
      } else {
        this.editDishForm.addControl(
          tag?.name,
          this.fb.array(tag.preferences.map(() => false))
        );

        this.tagsData = tag.preferences.map((pref) => ({ tagId: pref.id, tagName: pref.name }));
      }
    });
  }

  getTagItems(index: number) {
    return this.tags?.[index]?.preferences || this.tags?.[index]?.tags;
  }

  isTagSelected(
    parentId: string | number,
    tagId: number | string
  ): boolean {
    const dish = this.product.dish;
    const preferences = dish?.dietaryPreferences;
    const tags = dish?.headTagInfo;
    let isSelected = false;
    if (preferences?.[parentId]?.includes(tagId)) {
      isSelected = true;
    } else {
      const parentTagIndex = tags.findIndex((item) => item.headId == parentId);
      if (
        parentTagIndex >= 0 &&
        tags?.[parentTagIndex]?.tags?.find((item) => item?.tagId == tagId)
      ) {
        isSelected = true;
      }
    }
    return isSelected;
  }

  isAvoidanceSelected(id: number): boolean {
    return (
      this.product.dish?.dietaryPreferences?.[this.avoidancesId]?.includes(
        id
      ) || false
    );
  }

  getAvoidancesList() {
    return this.tags.find((item) => item.name == "Avoidances")?.preferences;
  }

  getMacroValue(macro) {
    if (macro === null || macro === undefined) return null;

    return String(macro)?.trim() || null;
  }

  saveDish(): void {
    if (!this.editDishForm.valid) {
      Swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });

      return;
    }

    this.loader.start();

    const headTagMapper = [];
    const preferencesMapper = {};

    this.tags?.forEach((tag) => {
      if (tag?.tags) {
        if (this.editDishForm.get(tag?.name)?.value?.length > 0) {
          headTagMapper.push({
            headId: tag?.id,
            tags: this.editDishForm.get(tag?.name)?.value?.map((item) => {
              return { tagId: item.id, tagName: item.name };
            }),
          });
        }
      } else if (tag?.preferences) {
        if (tag?.name == "Avoidances") {
          const allAvoidances = this.getAvoidancesList();
          const selected = [];
          this.editDishForm.get("Avoidances")?.value?.map((avoid, index) => {
            if (avoid) {
              selected.push(allAvoidances?.[index]?.id);
            }
          });
          if (selected?.length > 0) {
            preferencesMapper[tag?.id] = selected;
          }
        } else {
          preferencesMapper[tag?.id] = this.editDishForm
            .get(tag?.name)
            ?.value?.map((item) => item.id);
        }
      }
    });
    let payload = {
      dishId: this.product.dish.id,
      restaurantId: this.product.dish.restaurantId,
      carbs: this.getMacroValue(this.editDishForm?.get([this.FORM_KEYS.CARBS]).value),
      fat: this.getMacroValue(this.editDishForm.get(this.FORM_KEYS.FAT).value),
      protein: this.getMacroValue(this.editDishForm.get(this.FORM_KEYS.PROTEIN).value),
      totalCalories: this.editDishForm.get(this.FORM_KEYS.CALORIES).value,
      dishName: this.editDishForm.get(this.FORM_KEYS.DISH_NAME).value,
      headTagInfo: headTagMapper,
      dietaryPreferences: preferencesMapper,
      netPrice: this.product.dish?.netPrice,
      discount: this.product.dish?.discount,
      discountType: this.product.dish?.discountType,
      price: this.product.dish?.price,
      nutritionalInfo: this.currentDishTags,
      IsNew: this.product.dish?.IsNew,
      dishdetails: this.product.dish?.dishdetails,
      discImage: this.product.dish?.discImage,
    };

    this.dishService.editDish(payload).subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          Swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }

        this.setEditMode(false);
        this.loader.stop();

        this.reloadProduct.emit(null);
      },
      (err) => {
        Swal.fire({
          title: "Oops...",
          text:
            err?.error["serverResponse"]?.message || "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }

  parentReloadProduct() {
    this.reloadProduct.emit(null);
  }
}
