import { Component, OnInit, Input } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { DishService } from "../../services/dish/dish.service";
import { IoService } from "../../services/http/io.service";
import { TagManagementService } from "./../../services/tag-management/tag-management.service";
import Swal from "sweetalert2";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FiltersModal } from './filters-modal/filters-modal.component';
import { Filters } from './interfaces/filters.interface';

@Component({
  selector: "food-science",
  templateUrl: "./food-science.component.html",
  styleUrls: ["./food-science.component.css"],
})
export class FoodScienceComponent implements OnInit {
  @Input() resturantList;

  CLIPBOARD_STARTING_TEXT = 'MEP Food Science Dish ID: #';

  loading: boolean = true;

  currentDishTags: any[] = [];

  page: number = 1;
  limit: number = 50;

  orderByColumn: string = 'id';
  orderByDirection: string = 'desc';

  selectedDishIds: Set<number> = new Set<number>();
  totalPages: number = 1;
  pagesArr: number[] = [1];
  filters: Filters = {};
  tags: any = [];
  dishList: any = [];
  avoidancesId: any = "";
  expandAvoidances: boolean = false;
  editIndex: number = -1;
  editDishForm: FormGroup;
  tagsData: any[] = [];
  FORM_KEYS: {
    DISH_NAME: string;
    CARBS: string;
    PROTEIN: string;
    FAT: string;
    CALORIES: string;
  };
  paramObserver: Subscription;
  dropdownSettings: IDropdownSettings;
  tagsDropdownSettings: IDropdownSettings;
  constructor(
    private fb: FormBuilder,
    private dishService: DishService,
    private tagService: TagManagementService,
    private route: ActivatedRoute,
    private router: Router,
    private loader: NgxUiLoaderService,
    private io: IoService,
    private modalService: NgbModal
  ) {
    this.FORM_KEYS = {
      DISH_NAME: "dishName",
      CARBS: "carbs",
      PROTEIN: "protein",
      FAT: "fat",
      CALORIES: "calories",
    };
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
    this.tagsDropdownSettings = {
      singleSelection: false,
      idField: "tagId",
      textField: "tagName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
  }

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      this.setFiltersAndOrder(values);

      this.handleEditSwitch(-1);

      this.getDishes();
    });

    this.buildForm();
    this.getTags();
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  getDishes(): void {
    this.loading = true;

    this.dishService.getRestaurantDishes({
      page: this.page,
      limit: this.limit,
      orderBy: this.orderByColumn,
      orderDir: this.orderByDirection,
      filters: this.filters,
    }).subscribe(
      (res) => {
        this.dishList = res.dishes.map((dish) => ({
          ...dish,
          selected: this.selectedDishIds.has(dish.id),
        }));

        this.totalPages = res.totalPages;

        this.pagesArr = Array.from({ length: this.totalPages }, (_, i) => i + 1);
        this.loading = false;

        if (this.page > this.totalPages || this.page < 1) {
          this.changePage(1);
        }
      },
      () => {
        this.loading = false;
        Swal.fire({
          title: "Oops...",
          text: "Something went wrong while getting dishes!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }

  getTags(): void {
    this.tagService.listTagsAndDietPreferences().subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          this.tags = res.result;
          this.avoidancesId = res.result.find(
            (item) => item.name == "Avoidances"
          )?.id;
          this.addTagsToForm();
        }
      },
      () => {
        Swal.fire({
          title: "Oops...",
          text: "Something went wrong while getting tags!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }

  addTagsToForm(): void {
    this.tags?.forEach((tag) => {
      if (tag?.name != "Avoidances") {
        this.editDishForm.addControl(tag?.name, new FormControl([]));
      } else {
        this.editDishForm.addControl(
          tag?.name,
          this.fb.array(tag.preferences.map(() => false))
        );

        this.tagsData = tag.preferences.map((pref) => ({ tagId: pref.id, tagName: pref.name }));
      }
    });
  }

  buildForm(): void {
    this.editDishForm = this.fb.group({
      [this.FORM_KEYS.DISH_NAME]: ["", Validators.required],
      [this.FORM_KEYS.CALORIES]: ["", Validators.required],
      [this.FORM_KEYS.CARBS]: [""],
      [this.FORM_KEYS.PROTEIN]: [""],
      [this.FORM_KEYS.FAT]: [""],
    });
  }

  handleEditSwitch(index): void {
    this.editIndex = index;
    if (index >= 0) {
      const selectedDish = this.dishList[index];

      this.currentDishTags = selectedDish.nutritionalInfo.map(v => v);

      this.tags?.forEach((tag) => {
        if (tag?.name !== "Avoidances") {
          if (tag?.preferences) {
            const selectedItems =
              selectedDish?.dietaryPreferences?.[tag?.id] || [];
            const selectedTags = [];
            for (let i = 0; i < selectedItems?.length; i++) {
              selectedTags.push(
                tag?.preferences.find((item) => item?.id == selectedItems[i])
              );
            }
            this.editDishForm.get(tag?.name).setValue(selectedTags);
          } else {
            const selectedItems = selectedDish?.headTagInfo?.find(
              (item) => item.headId == tag?.id
            )?.tags;
            this.editDishForm.get(tag?.name).setValue(
              selectedItems?.map((item) => {
                return { id: item.tagId, name: item.tagName };
              }) || []
            );
          }
        } else {
          const allAvoidances = this.getAvoidancesList();
          const selectedAvoidances = [];
          allAvoidances.map((avoid) => {
            selectedAvoidances.push(this.isAvoidanceSelected(avoid?.id, index));
          });
          this.editDishForm.get(tag?.name).setValue(selectedAvoidances);
        }
      });

      this.editDishForm.patchValue({
        [this.FORM_KEYS.DISH_NAME]: selectedDish.dishName,
        [this.FORM_KEYS.CARBS]: selectedDish.carbs,
        [this.FORM_KEYS.PROTEIN]: selectedDish.protein,
        [this.FORM_KEYS.FAT]: selectedDish.fat,
        [this.FORM_KEYS.CALORIES]: selectedDish.totalCalories,
      });
    } else {
      this.currentDishTags = [];
      this.resetForm();
    }
  }

  resetForm() {
    this.editDishForm?.reset();
  }

  getMacroValue(macro) {
    if (macro === null || macro === undefined) return null;

    return String(macro)?.trim() || null;
  }

  handleEdit(): void {
    if (!this.editDishForm.valid) {
      Swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return;
    }

    this.loader.start();
    const headTagMapper = [];
    const preferencesMapper = {};
    this.tags?.forEach((tag) => {
      if (tag?.tags) {
        if (this.editDishForm.get(tag?.name)?.value?.length > 0) {
          headTagMapper.push({
            headId: tag?.id,
            tags: this.editDishForm.get(tag?.name)?.value?.map((item) => {
              return { tagId: item.id, tagName: item.name };
            }),
          });
        }
      } else if (tag?.preferences) {
        if (tag?.name == "Avoidances") {
          const allAvoidances = this.getAvoidancesList();
          const selected = [];
          this.editDishForm.get("Avoidances")?.value?.map((avoid, index) => {
            if (avoid) {
              selected.push(allAvoidances?.[index]?.id);
            }
          });
          if (selected?.length > 0) {
            preferencesMapper[tag?.id] = selected;
          }
        } else {
          preferencesMapper[tag?.id] = this.editDishForm
            .get(tag?.name)
            ?.value?.map((item) => item.id);
        }
      }
    });

    let payload = {
      dishId: this.dishList[this.editIndex].id,
      restaurantId: this.dishList[this.editIndex].restaurantId,
      carbs: this.getMacroValue(this.editDishForm?.get([this.FORM_KEYS.CARBS]).value),
      fat: this.getMacroValue(this.editDishForm.get(this.FORM_KEYS.FAT).value),
      protein: this.getMacroValue(this.editDishForm.get(this.FORM_KEYS.PROTEIN).value),
      totalCalories: this.editDishForm.get(this.FORM_KEYS.CALORIES).value,
      dishName: this.editDishForm.get(this.FORM_KEYS.DISH_NAME).value,
      headTagInfo: headTagMapper,
      dietaryPreferences: preferencesMapper,
      netPrice: this.dishList[this.editIndex]?.netPrice,
      discount: this.dishList[this.editIndex]?.discount,
      discountType: this.dishList[this.editIndex]?.discountType,
      price: this.dishList[this.editIndex]?.price,
      nutritionalInfo: this.currentDishTags,
      IsNew: this.dishList[this.editIndex]?.IsNew,
      dishdetails: this.dishList[this.editIndex]?.dishdetails,
      discImage: this.dishList[this.editIndex]?.discImage,
    };
    this.dishService.editDish(payload).subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          Swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        this.handleEditSwitch(-1);
        this.loader.stop();
        this.getDishes();
      },
      (err) => {
        Swal.fire({
          title: "Oops...",
          text:
            err?.error["serverResponse"]?.message || "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }

  async savePublishAndActivate() {
    if (!this.editDishForm.valid) {
      Swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return;
    }

    try {
      const dishId = this.dishList[this.editIndex].id;

      await this.io.apicall({ dishId }, 'dish/dish-publish-activate', 'POST');

      this.handleEdit();
    } catch {
      Swal.fire({
        title: 'Oops...',
        text: 'Something went wrong, please try again!',
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    }
  }

  getAvoidancesList() {
    return this.tags.find((item) => item.name == "Avoidances")?.preferences;
  }

  isAvoidanceSelected(id: string | number, index: number): boolean {
    return (
      this.dishList?.[index]?.dietaryPreferences?.[this.avoidancesId]?.includes(
        id
      ) || false
    );
  }

  getTagItems(index: number) {
    return this.tags?.[index]?.preferences || this.tags?.[index]?.tags;
  }

  isTagSelected(
    dishIndex: number,
    parentId: string | number,
    tagId: number | string
  ): boolean {
    const dish = this.dishList[dishIndex];
    const preferences = dish?.dietaryPreferences;
    const tags = dish?.headTagInfo;
    let isSelected = false;
    if (preferences?.[parentId]?.includes(tagId)) {
      isSelected = true;
    } else {
      const parentTagIndex = tags.findIndex((item) => item.headId == parentId);
      if (
        parentTagIndex >= 0 &&
        tags?.[parentTagIndex]?.tags?.find((item) => item?.tagId == tagId)
      ) {
        isSelected = true;
      }
    }
    return isSelected;
  }

  async publishDish(dishId: number) {
    try {
      const res = await this.io.apicall({ dishId }, 'dish/dish-publish-unpublish', 'POST');

      Swal.fire({
        title: 'Success',
        text: res['serverResponse'].message,
        icon: 'success',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });

      this.getDishes();
    }
    catch (err) {
      Swal.fire({
        title: 'Oops...',
        text: this.io.data_.serverResponse.message,
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    }
  }

  async setDishActiveStatus(dishId: number, isActive: boolean) {
    try {
      const res = await this.io.apicall({ dishId, isActive }, 'dish/change-dish-status', 'POST');

      Swal.fire({
        title: 'Success',
        text: res['serverResponse'].message,
        icon: 'success',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });

      this.getDishes();
    }
    catch (err) {
      Swal.fire({
        title: 'Oops...',
        text: this.io.data_.serverResponse.message,
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    }
  }

  handleSelectionChange(dish) {
    if (dish.selected) {
      this.selectedDishIds.add(dish.id);
    } else {
      this.selectedDishIds.delete(dish.id);
    }
  }

  handleSelectAll(event) {
    if (!this.dishList?.length) return;

    if (event.target.checked) {
      this.dishList.forEach((dish) => {
        dish.selected = true;
        this.selectedDishIds.add(dish.id);
      });
    } else {
      this.dishList.forEach((dish) => {
        dish.selected = false;
        this.selectedDishIds.delete(dish.id);
      });
    }
  }

  async publishSelectedDishes() {
    if (!this.selectedDishIds.size) return;

    try {
      await this.io.apicall({ ids: Array.from(this.selectedDishIds) }, 'dish/dish-publish-bulk', 'POST');

      this.selectedDishIds = new Set<number>();

      this.getDishes();
    } catch {
      Swal.fire({
        title: 'Oops...',
        text: 'Something went wrong, please try again!',
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    }
  }

  async openFiltersModal() {
    const modalRef = this.modalService.open(FiltersModal, { centered: true, modalDialogClass: 'dish__food-science__filters-modal' });

    modalRef.componentInstance.activeFilters = this.filters;
    modalRef.componentInstance.resturantList = this.resturantList;
    modalRef.componentInstance.tags = this.tags;
    modalRef.componentInstance.tagsLabelsData = this.tagsData;

    try {
      this.filters = await modalRef.result;

      this.selectedDishIds = new Set<number>();

      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {
            page: 1,
            restaurantIds: JSON.stringify(this.filters.restaurantIds),
            dishName: this.filters.dishName,
            active: this.filters.active,
            publish: this.filters.publish,
            caloriesMin: this.filters.caloriesMin,
            caloriesMax: this.filters.caloriesMax,
            carbsMin: this.filters.carbsMin,
            carbsMax: this.filters.carbsMax,
            proteinMin: this.filters.proteinMin,
            proteinMax: this.filters.proteinMax,
            fatMin: this.filters.fatMin,
            fatMax: this.filters.fatMax,
            preferenceIds: JSON.stringify(this.filters.preferenceIds),
            tagIds: JSON.stringify(this.filters.tagIds),
            tagLabelIds: JSON.stringify(this.filters.tagLabelIds),
          },
          queryParamsHandling: 'merge'
        });
    }
    catch (err) { }
  }

  changePage(page: number) {
    this.selectedDishIds = new Set<number>();

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page },
        queryParamsHandling: 'merge'
      });
  }

  changeOrderByColumn(orderBy: string) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { orderBy },
        queryParamsHandling: 'merge'
      });
  }

  changeOrderByDirection(orderDir: string) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { orderDir },
        queryParamsHandling: 'merge'
      });
  }

  setFiltersAndOrder(params: Params) {
    if (params.page) {
      this.page = Number(params.page);
    } else {
      this.page = 1;
    }

    if (params.orderBy) {
      this.orderByColumn = params.orderBy;
    } else {
      this.orderByColumn = 'id';
    }

    if (params.orderDir) {
      this.orderByDirection = params.orderDir;
    } else {
      this.orderByDirection = 'desc';
    }

    if (params.restaurantIds) {
      this.filters.restaurantIds = JSON.parse(params.restaurantIds);
    }
    else if (params.restaurant) {
      this.filters.restaurantIds = [Number(params.restaurant)];
    }

    if (params.dishName) {
      this.filters.dishName = params.dishName;
    }

    if (params.active) {
      this.filters.active = params.active;
    }

    if (params.publish) {
      this.filters.publish = params.publish;
    }

    if (params.caloriesMin) {
      this.filters.caloriesMin = Number(params.caloriesMin);
    }

    if (params.caloriesMax) {
      this.filters.caloriesMax = Number(params.caloriesMax);
    }

    if (params.carbsMin) {
      this.filters.carbsMin = Number(params.carbsMin);
    }

    if (params.carbsMax) {
      this.filters.carbsMax = Number(params.carbsMax);
    }

    if (params.proteinMin) {
      this.filters.proteinMin = Number(params.proteinMin);
    }

    if (params.proteinMax) {
      this.filters.proteinMax = Number(params.proteinMax);
    }

    if (params.fatMin) {
      this.filters.fatMin = Number(params.fatMin);
    }

    if (params.fatMax) {
      this.filters.fatMax = Number(params.fatMax);
    }

    if (params.preferenceIds) {
      this.filters.preferenceIds = JSON.parse(params.preferenceIds);
    }

    if (params.tagIds) {
      this.filters.tagIds = JSON.parse(params.tagIds);
    }

    if (params.tagLabelIds) {
      this.filters.tagLabelIds = JSON.parse(params.tagLabelIds);
    }
  }

  copyDishIdToClipboard(dish) {
    if (!dish) return;

    navigator.clipboard.writeText(this.CLIPBOARD_STARTING_TEXT + dish.id.toString());

    Swal.fire({
      title: 'Dish attributes copied!',
      icon: 'success',
    });
  }

  async pasteDishFromClipboard(dish) {
    if (!dish) return;

    const clipboardText = await navigator.clipboard.readText();

    if (!clipboardText.includes(this.CLIPBOARD_STARTING_TEXT)) {
      Swal.fire({
        title: 'Clipboard doesn\'t contain a valid dish!',
        icon: 'error',
      });

      return;
    }

    const sourceDishId = Number(clipboardText.replace(this.CLIPBOARD_STARTING_TEXT, ''));

    if (Number.isNaN(sourceDishId)) {
      Swal.fire({
        title: 'Clipboard doesn\'t contain a valid dish!',
        icon: 'error',
      });

      return;
    }

    try {
      await this.io.apicall({ sourceDishId, targetDishId: dish.id }, 'dish/portal/copy-dish-attribtutes', 'POST');

      Swal.fire({
        title: 'Dish attributes pasted!',
        icon: 'success',
      });

      this.getDishes();
    } catch {
      Swal.fire({
        title: 'Oops...',
        text: 'Something went wrong, please try again!',
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok',
      });
    }
  }
}
